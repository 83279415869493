<template>
    <div class="content" style="caret-color: transparent;">
        <img class="logImg" :src="homeLog" alt="log图片">
        <!-- 登录部分 -->
        <wxlogin class="loginBox" appid='wx5423325066e8e69b' scope="snsapi_login" :redirect_uri='url' :state="state">
        </wxlogin>

    </div>
</template>

<script>
import { POST_LOGINNEXT } from "../request/api";
let Base64 = require('js-base64').Base64
export default {
    data() {
        return {
            homeLog: "https://static.hearming.com/oa/img/yigologo.png",

            url: "http://www.hearming.com/wx/callback/scanLogin/",
            state: this.encode(
                '{"option":"www-hearming-com-oa", "random":"100000", "t":' +
                    Date.parse(new Date()) +
                    "}"
            ),
        };
    },
    created() {
        this.loginNext();
        console.log(this.state);
    },
    methods: {
        encode(str) {
            return Base64.encode(str);
        },
        //判断用户状态
        async loginNext() {
            const token = localStorage.getItem("token");
            if (!token) return;
            const { data: res } = await POST_LOGINNEXT();
            if (res.data.next == 2) {
                this.$notify.warning({
                    title: "提示",
                    message: "请先完善您的基本信息",
                });
                this.$router.push({ path: "/baseinfo" });
            } else if (res.data.next == 3) {
                setTimeout(() => {
                    this.$router.push({ path: "/hmNeed" });
                }, 1000);
            }
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url("https://static.hearming.com/oa/img/index-bg.jpg");

    /* 背景图片设置 */
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;

    // log图片
    .logImg {
        width: 160px;
        height: 160px;
        top: 80px;
        left: 80px;
        position: absolute;
    }

    //   登录部分
    .loginBox {
        width: 500px;
        height: 431px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        background-color: #fff;

        text-align: center;
        padding-top: 25px;
    }
}
</style>