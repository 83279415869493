import Vue from 'vue'
import {
    Button,
    Container,
    Header,
    Aside,
    Main,
    Menu,
    MenuItem,
    Avatar,
    Card,
    Form,
    FormItem,
    Input,
    Select,
    Option,
    Col,
    Row,
    Dialog,
    Message,
    MessageBox,
    Table,
    TableColumn,
    Pagination,
    Notification,
    Loading,
    Popconfirm,
    Popover,
    Tooltip,
    Link,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Upload,
    Drawer,
    Transfer,
    Submenu,
    DatePicker,
    Tag,
    Slider,
    Image,
    Radio,
    RadioGroup
} from 'element-ui'

Vue.use(Button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Avatar)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Col)
Vue.use(Row)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Popconfirm)
Vue.use(Popover)
Vue.use(Tooltip)
Vue.use(Link)
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Upload);
Vue.use(Drawer);
Vue.use(Transfer);
Vue.use(Submenu);
Vue.use(DatePicker);
Vue.use(Tag);
Vue.use(Slider);
Vue.use(Image);
Vue.use(Radio);
Vue.use(RadioGroup);

Vue.use(Loading.directive);
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$confirm = MessageBox.confirm;