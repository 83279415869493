import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        redirect: 'created',
        component: Home,
        children: [{
            path: '/created',
            component: () =>
                import ('../components/created.vue')
        }, {
            path: '/interface',
            component: () =>
                import ('../components/interface.vue')

        }, {
            path: '/defects',
            component: () =>
                import ('../components/defects.vue')
        }, {
            path: '/hmNeed',
            component: () =>
                import ('../components/hmNeed.vue')
        }, {
            path: '/hmNeedAdd',
            meta: {
                newRoute: "/hmNeed"
            },
            component: () =>
                import ('../components/hmNeedAdd.vue')
        }, {
            path: '/hmNeedEdit',
            meta: {
                newRoute: "/hmNeed"
            },
            component: () =>
                import ('../components/hmNeedEdit.vue')
        }, {
            path: '/hmNeedDetails',
            meta: {
                newRoute: "/hmNeed"
            },
            component: () =>
                import ('../components/hmNeedDetails.vue')
        }, {
            path: '/dateCharts',
            component: () =>
                import ('../components/dateCharts.vue')
        }, {
            path: '/userList',
            component: () =>
                import ('../components/userList.vue')
        }, {
            path: '/workPlan',
            component: () =>
                import ('../components/workShop/workPlan.vue')
        }, {
            path: '/workReport',
            component: () =>
                import ('../components/workShop/workReport.vue')
        }, {
            path: '/workScore',
            component: () =>
                import ('../components/workShop/workScore.vue')
        }, {
            path: '/upload',
            component: () =>
                import ('../components/parts/upload.vue')

        }, {
            path: '/journal',
            component: () =>
                import ('../components/journal.vue')

        }, ]
    }, {
        path: '/index',
        component: index,
    }, {
        path: '/baseinfo',
        component: () =>
            import ('../views/baseInfo.vue')
    }

]

const router = new VueRouter({
    routes
})

//创建一个路由守卫
// router.beforeEach((to, from, next) => {
//     if (to.path === "/Index") return next()
//     if (to.path === "/baseinfo") return next()
//     const token = window.localStorage.getItem('token')
//     if (!token) return next('/Index') //如果没有token就强制跳转到登录
//     next() //如果有权限就放行
// })

export default router