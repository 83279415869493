// axios拦截配置文件
import axios from 'axios'
import {
    Notification
} from "element-ui";
import router from '../router'

//创建一个单例
const instance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: 'https://oa.hearming.com/hm/oa',
})

//拦截器- 请求拦截器
instance.interceptors.request.use(config => {
    const token = window.localStorage.getItem('token')
    if (token) {
        config.headers.token = token
    }
    return config
}, err => {
    return Promise.reject(err)
})

//拦截器- 相应拦截器
let timeout = true
instance.interceptors.response.use((response) => {
    if (response.status === 200) {
        if (response.data.resCode == 10000) {
            if (timeout) {
                Notification.error({
                    title: '错误',
                    message: '登录过期，请重新登录'
                });
                // 登录过期删除token
                window.localStorage.removeItem('token')
                setTimeout(() => {
                    router.push("index")
                }, 1000);
                //如果状态码等于10000，设置过期为true
                timeout = false
                return Promise.reject(response)
            } else {
                router.push("index")
                return Promise.reject("已删除token")
            }
        } else if (response.data.resCode == 1) {
            timeout = true
            return Promise.resolve(response)
        } else {
            timeout = true
            Notification.error({
                title: '错误',
                message: response.data.resMsg
            });
            return Promise.reject(response)
        }
    } else {
        return Promise.reject(response)
    }
}, err => {
    return Promise.reject(err)
})


/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        instance.post(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//整体导出
// export default instance
