import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/global.css'
import axios from 'axios'
import util from './request/util'
import wxlogin from 'vue-wxlogin'; //  引入微信登录插件
import "default-passive-events"; //添加事件管理者'passive'，来阻止'touchstart'事件，让页面更加流畅。 解决chrome下的warning问题

Vue.component('wxlogin', wxlogin)
Vue.prototype.$axios = axios
Vue.prototype.$util = util

Vue.config.productionTip = false
Vue.config.devtools = true

// 过滤器
Vue.filter("StringDate", (value) => {
    if (!value) return ''
    value = value.substring(0, 10)
    return value
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')