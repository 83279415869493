import { POST_QUERYMENBER, POST_PRJQUPAGE } from '../request/api'
import store from '../store/index'

export default {
    getMember() {
        const Member = new Promise((resolve, reject) => {
            POST_QUERYMENBER({
                    pageNumber: 1,
                    pageSize: 30,
                })
                .then(({ data: res }) => {
                    if (res.resCode == 1) {
                        var result = res.data.list.map((item) => {
                            return { value: item.userName, label: item.userName, key: item.id };
                        });
                        store.dispatch("saveMember", result)
                        sessionStorage.setItem('Member', JSON.stringify(result))
                        resolve(result)
                    }
                })
                .catch((err) => {
                    reject(err)
                });
        })
        return Member
    },
    getProName() {
        const projectName = new Promise((resolve, reject) => {
            POST_PRJQUPAGE({})
                .then(({ data: res }) => {
                    var result = res.data.map((item) => {
                        return {
                            id: item.id,
                            value: item.proName,
                            label: item.proName,
                            proVersion: item.proVersion,
                            proManager: item.proManager,
                        };
                    });
                    store.dispatch("saveProject", result)
                    sessionStorage.setItem('projectName', JSON.stringify(result))
                    resolve(result)
                })
                .catch((err) => {
                    reject(err)
                });
        })
        return projectName
    },

}