// 将request.js整体导入
// import request from './request'
import {get, post } from './request'

// 按需导入需要的api接口

/*用户管理*/
// 登录接口
export const POST_LOGIN = (params) => post('/user/sso/wx5423325066e8e69b/login', params)
    // 编辑用户信息
export const POST_EDITLOGINUSER = (params) => post('/user/sso/wx5423325066e8e69b/editUser', params)

// 登录后用户页面下一步操作2-下一步完善用户信息 3-直接进入xx首页面
export const POST_LOGINNEXT = () => post('/user/sso/wx5423325066e8e69b/loginNext')
    // 查找当前用户信息
export const POST_CURRENTUSER = () => post('/user/sso/wx5423325066e8e69b/findUser')
    // 退出登录
export const POST_LOGOUT = () => post('/user/sso/wx5423325066e8e69b/logout')
    // 查询七牛云Token
export const POST_QINIUTOKEN = () => post('/token/qiNiu/getToken')

/*
立项管理
*/
//分页查询 "项目"
export const POST_PRJQULIST = (params) => post('/api/project/queryProjectPageList', params)
    //查询项目
export const POST_PRJQUPAGE = (params) => post('/api/project/queryList', params)
    //新增或者编辑 "项目"
export const POST_PRJEDITADD = (params) => post('/api/project/addEditProject', params)
    //根据主键查找 "项目"
export const GET_PRJBYID = (params) => get('/api/project/getProject', params)
    //根据主键查找 "项目"
export const POST_FINDPRJBYID = (params) => post('/api/project/findProject', params)
    //删除 "项目"
export const GET_DELEPRJBYID = (params) => get('/api/project/deleteById', params)


/*接口管理*/
// 分页查询"接口"
export const POST_QUERYAPILIST = (params) => post('/api/api/queryApiPageList', params)
    // 新增或者编辑"接口"
export const POST_ADDEDITAPI = (params) => post('/api/api/addEditApi', params)
    // 根据主键查找"接口"
export const POST_FINDAPIBYID = (params) => post('/api/api/findApi', params)
    // 根据主键删除"接口"
export const GET_DELEAPIBYID = (params) => get('/api/api/deleteById', params)


/*缺陷管理*/
// 分页查询 缺陷 记录
export const POST_QUERYDEFECT = (params) => post('/api/defects/queryDefectsPageList', params)
    // 新增或者编辑 缺陷 记录
export const POST_ADDEDITDEF = (params) => post('/api/defects/addEditDefects', params)
    // 根据主键查找 缺陷
export const POST_FINDDEFECT = (params) => post('/api/defects/findDefects', params)
    // 根据主键删除"接口"
export const GET_DELEDEFECT = (params) => get('/api/defects/deleteById', params)



/*需求管理*/
// 查询 HmNeed 列表
export const POST_QUERYNEED = (params) => post('/api/need/queryNeedPageList', params)
    // 新增或者编辑 "需求"
export const POST_ADDEDITNEED = (params) => post('/api/need/addEditNeed', params)
    // 根据主键查找"需求"
export const POST_FINDNEED = (params) => post('/api/need/findNeed', params)
    // 根据主键删除"需求"
export const GET_DELENEED = (params) => get('/api/need/deleteById', params)
    // 根据主键批量删除"需求"
export const POST_DELENEEDLIST = (params) => post('/api/need/deleteByIdList', params)
export const POST_queryNeedGroupInfo = (params) => post('/api/need/queryNeedGroupInfo', params)
    // 需求转项
export const POST_transferNeeds = (params) => post('/api/need/transferNeeds', params)
    // 置顶或取消置顶需求。 交替动作
export const POST_topNeed = (params) => post('/api/need/topNeed', params)
    // 关注需求的变化。 交替动作
export const POST_careNeed = (params) => post('/api/need/careNeed', params)
    // 转发人员列表及邮箱
export const POST_findInsideMembers = (params) => post('/api/need/findInsideMembers', params)
    // 转发到邮箱
export const POST_sendToEmail = (params) => post('/api/need/sendToEmail', params)

// 新增或者编辑 评论 记录
export const POST_addEditComment = (params) => post('/api/feedback/comment/addEditComment', params)

// 分页查询 评论 记录
export const POST_queryCommentPageList = (params) => post('/api/feedback/comment/queryCommentPageList', params)

// 根据查找下一个上一个"需求" 没有找到返回data null
export const POST_findNextNeed = (params) => post('/api/need/findNextNeed', params)



/*成员管理*/
//查询 "项目成员" 列表
export const POST_QUERYMENBER = (params) => post('/api/member/findMemberLists', params)
    //审核成员
export const POST_AUDITMEMBER = (params) => post('/api/member/auditMember', params)
    //新增或者编辑"项目成员"
export const POST_ADDMEMBER = (params) => post('/api/member/addEditMember', params)


/*图表管理*/
// 缺陷状态统计
export const POST_DEFECTCHART = (params) => post('/api/chart/defectsChart', params)
    // 所有项目的缺陷状态统计
export const POST_CHARTLIST = (params) => post('/api/chart/defectsChartList', params)



/*分配项目管理*/
// 用户列表
// 查询所有项目列表及指定的项目成员的项目列表
export const POST_FINDMBPJT = (params) => post('/api/member/project/findUserProjects', params)
    // 给成员分配项目
export const POST_ASSIGNMB = (params) => post('/api/member/project/assignProjectToMember', params)




/* 分页查询工作计划 */
export const PLAN_QUERTLIST = (params) => post('/api/work/plan/queryMobWorkPlanPageList', params)
    /* 新增或者编辑工作计划 */
export const PLAN_ADDEDIT = (params) => post('/api/work/plan/addEditMobWorkPlan', params)
    /* 根据主键查找工作计划 */
export const PLAN_FINDBYID = (params) => post('/api/work/plan/findMobWorkPlan', params)
    /* 批量删除我的"工作计划" */
export const PLAN_DELEBYID = (params) => post('/api/work/plan/deleteByIdList', params)



/* 分页查询 "工作报告" */
export const REPORT_QUERTLIST = (params) => post('/api/work/report/findWorkReportPage', params)
    /* 分页查询 "工作报告" */
export const REPORT_ADDEDIT = (params) => post('/api/work/report/addEditMobWorkReport', params)
    /* 根据主键查找 "工作报告" */
export const REPORT_FINDBYID = (params) => post('/api/work/report/findMobWorkReport', params)
    /* 删除我的"工作报告" */
export const REPORT_DELEBYID = (params) => post('/api/work/report/deleteByIdList', params)



/* 分页查询工作绩效 */
export const SCORE_QUERTLIST = (params) => post('/api/work/score/findWorkScorePage', params)
    /* 新增或者编辑工作绩效 */
export const SCORE_ADDEDIT = (params) => post('/api/work/score/addEditMobWorkScore', params)
    /* 根据主键查找工作绩效 */
export const SCORE_FINDBYID = (params) => post('/api/work/score/findMobWorkScore', params)
    /* 批量删除我的"工作绩效" */
export const SCORE_DELEBYID = (params) => post('/api/work/score/deleteByIdList', params)


/* 项目集列表 */
export const GROUP_QUERTLIST = (params) => post('/api/HmProjectGroup/queryHmProjectGroupPageList', params)
    /* 新增或者编辑"项目集" */
export const GROUP_ADDEDIT = (params) => post('/api/HmProjectGroup/addEditHmProjectGroup', params)
    /* 根据主键查找"项目集" */
export const GROUP_FINDBYID = (params) => post('/api/HmProjectGroup/findHmProjectGroup', params)
    /* 批量删除"项目集"记录, 返回删除的主键*/
export const GROUP_DELEBYID = (params) => post('/api/HmProjectGroup/deleteByIdList', params)


// 日志
export const GROUP_queryLogPage = (params) => post('/api/log/timeline/queryLogPage', params)
export const SAVE_Persistent = (params) => post('/api/period/persistent', params)
