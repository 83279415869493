<template>
    <div>
        <el-container style="min-width: 1200px;">
            <!-- 侧边栏区域 -->
            <el-aside width="150px">
                <div class="asideTitle">合鸣科技后台</div>
                <el-menu :default-active="activePath" router class="asideMenu" background-color="#393D49" text-color="#fff">
                    <el-menu-item :index="items.path" v-for="items in asideList" :key="items.id" @click='saveNacstate(items.path)'>
                        <span slot="title">{{items.name}}</span>
                    </el-menu-item>
                    <el-menu-item index="/journal" @click='saveNacstate(path="journal")'>
                        <span slot="title">日志</span>
                    </el-menu-item>

                    <el-submenu index="2">
                        <template slot="title">我的工作台</template>
                        <el-menu-item index="/workplan" @click='saveNacstate(path="workplan")'>工作计划
                        </el-menu-item>
                        <el-menu-item index="/workReport" @click='saveNacstate(path="workReport")'>工作报告
                        </el-menu-item>
                        <el-menu-item index="/workScore" @click='saveNacstate(path="workScore")'>工作绩效
                        </el-menu-item>
                    </el-submenu>

                </el-menu>

                <el-button type="primary" @click="drawer = true" icon="el-icon-s-operation">
                    {{currentProject ? currentProject : '项目切换'}}
                </el-button>

            </el-aside>

            <el-container>
                <!-- 头部区域 -->
                <el-header class="Header">
                    <div class="Header-ones">
                        <div class="first">
                            <span v-if="currentProject" @click="drawer = true">当前项目： {{currentProject}}</span>
                        </div>
                        <div class="cent">
                            <span v-for="project in recents" :key="project.id" @click="selectProject(project)">{{project.label}}</span>
                        </div>
                        <div class="second">
                            <div>
                               <span v-if="s_flag"> <i class="el-icon-loading"></i> 存档中</span>
                               <span v-if="!s_flag" class="btn" icon="el-icon-coin" @click="persistent()" >存档-hm</span>
                            </div>
                            <div>
                                <el-tooltip class="item" effect="dark" content="motto: Keep calm and keep yourself updated!" placement="left">
                                    <el-avatar :size="30" :src="avatar"></el-avatar>
                                </el-tooltip>
                            </div>
                            <div>
                                <el-dropdown trigger="click" @command="handleCommand">
                                    <el-button type="text" class="el-dropdown-link">
                                        {{realName ?  realName : '设置'}}<i class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="a">设置</el-dropdown-item>
                                        <el-dropdown-item command="b">退出登录</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown> 
                            </div>
                        </div>
                    </div>
                </el-header>
                <!-- 内容区域 -->
                <el-main>
                    <el-card class="mainCard">
                        <router-view @updateProject="updateProject"></router-view>
                    </el-card>
                </el-main>
            </el-container>
        </el-container>

        <el-drawer title="项目列表" class="drawer" size="200px" :with-header="false" :visible.sync="drawer" :direction="direction">
            <span class="projectTitle">项目名称</span>
            <el-menu :default-active="currentId" class="projectMenu" background-color="#393D49" text-color="#fff">
                <el-menu-item :index="project.id+''" v-for="project in projectList" :key="project.id" @click="selectProject(project)">
                    <span slot="title">ID:{{project.id}} {{project.label}}</span>
                </el-menu-item>
            </el-menu>
        </el-drawer>

        <el-dialog title="退出登录" :modal-append-to-body="false" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <!-- style="z-index: 10001; top:165px;" -->
            <span>这是一段信息</span>
            <span>这是一段信息</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { POST_CURRENTUSER, POST_LOGOUT, SAVE_Persistent } from "../request/api";
export default {
    data() {
        return {
            realName: "",
            avatar: "",
            currentProject: "",
            currentId: "",

            drawer: false, //抽屉是否打开
            direction: "ltr", //抽屉方向

            activePath: "/hmNeed",
            asideList: [],

            projectList: [],
            //控制dialog显示与隐藏
            dialogVisible: false,
            recents:[], 
            s_flag:false,
            timeoutID:null
        };
    },
    created() {
        this.currentUser();
        this.updateProject();
        this.activePath = sessionStorage.getItem("activePath");
    },
    mounted() {
        //刷新之后查找状态里是否有项目信息
        const currentProject = this.$store.state.currentProject;
        if (currentProject.id) {
            this.saveSession(
                currentProject.id,
                currentProject.label,
                currentProject.proVersion,
                currentProject.proManager
            );
        } else {
            setTimeout(() => {
                const defaultVal = this.projectList[0];
                this.$store.dispatch("saveCurrentProject", defaultVal);
                this.saveSession(
                    defaultVal.id,
                    defaultVal.label,
                    defaultVal.proVersion,
                    defaultVal.proManager
                );
            }, 800);
        }
    },
    methods: {
        // 获取项目列表数据
        updateProject(val) {
            const exist= JSON.parse( sessionStorage.getItem("projectName"));
            if (exist) {
                const projectList = JSON.parse( sessionStorage.getItem("projectName"));
                this.projectList = projectList;

                this.$util.getProName();
                this.$util.getMember();
            } else {
                this.$util.getProName().then((res) => {
                    this.projectList = res.reverse();
                });
            }

            if (val) {
                this.saveSession(
                    val.id,
                    val.proName,
                    val.proVersion,
                    val.proManager
                );
            }
        },
        // 查找当前登录用户信息
        currentUser() {
            POST_CURRENTUSER()
                .then(({ data: res }) => {
                    if (res.resCode == 1) {
                        this.avatar = res.data.avatar||"https://static.hearming.com/oa/img/logo.png";
                        this.realName = res.data.realName;
                        this.asideList = res.data.asideMenu;
                        this.$store.dispatch("saveUserName", res.data.realName); //保存当前用户信息
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 保存当前侧边栏激活信息
        saveNacstate(activePath) {
            console.log(activePath);
            this.activePath = activePath;
            window.sessionStorage.setItem("activePath", activePath);
        },
        logOut() {
            POST_LOGOUT()
                .then(({ data: res }) => {
                    if (res.resCode == 1) {
                        this.$notify.success({
                            title: "成功",
                            message: "退出成功",
                        });
                        window.localStorage.removeItem("token");
                        window.sessionStorage.clear();
                        window.localStorage.clear();
                        setTimeout(() => {
                            this.$router.push("index");
                        }, 1000);
                    } else {
                        this.$notify.error({
                            title: "失败",
                            message: res.resMsg,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClose() {
            this.dialogVisible = false;
            console.log();
        },
        handleCommand(command) {
            if (command == "b") {
                console.log();
            }
            if (command == "b") {
                this.$confirm("是否要退出登录？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.logOut();
                    })
                    .catch(() => {});
            }
        },
        //保存选中项目的id，名称，以及版本号
        selectProject(project) {
            const currentProject = {
                id:project.id,
                label:project.label,
                proVersion:project.proVersion,
                proManager:project.proManager,
            };
            this.$store.dispatch("saveCurrentProject", currentProject);
            this.saveSession(project.id, project.label, project.proVersion, project.proManager);
            this.drawer = false;
            let found = this.recents.find((item)=>item.id==project.id)||{};
            if(Object.keys(found).length==0){
                this.recents.unshift(currentProject);
                if(this.recents.length>5){
                    this.recents.pop();
                }
            }
        },
        saveSession(id, label, proVersion, proManager) {
            this.currentProject = label;
            this.currentId = id + "";
            window.sessionStorage.setItem("currentId", id);
            window.sessionStorage.setItem("currentlabel", label);
            window.sessionStorage.setItem("currentProVersion", proVersion);
            window.sessionStorage.setItem("currentProManager", proManager);
        },
        persistent(){
            let params = {
                name:"oa",
                random:Math.floor(Math.random()*100000000),
                timestamp:Date.now(), /*毫秒数*/
            };
            this.timeoutID = window.setTimeout(() => {
                this.s_flag=false;
            }, 60*1000);
            let that = this;
            that.s_flag=true; 
            SAVE_Persistent(params).then(({data:res})=>{
                if (res.resCode == 1) {
                    this.$notify.success({
                        title: "成功",
                        message: "退出成功",
                    });
                }else{ 
                    this.$notify.error({
                        title: "失败",
                        message: res.resMsg,
                    });
                }
                that.s_flag=false;
                window.clearTimeout(that.timeoutID);
            }).catch((err) => {
                console.log(err); 
                that.s_flag=false;
                window.clearTimeout(that.timeoutID);
            });
        },
    },
    watch: {
        $route(newValue) {
            let newPath = newValue.fullPath;
            if (newValue.meta) {
                if (newValue.meta.newRoute) {
                    newPath = newValue.meta.newRoute;
                }
            }
            this.saveNacstate(newPath);
        },
    },
};
</script>

<style lang="less" scoped>
.el-aside {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background: #282b33;

    .el-button {
        width: 100%;
        border-radius: 0;
        position: absolute;
        bottom: 30px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.asideTitle {
    width: 150px;
    height: 60px;
    font-size: 18px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
}
.asideMenu {
    border: 0;

    /deep/ .el-menu-item {
        color: #fff;
        height: 50px;
        font-size: 14px;
        font-weight: 400;
        // text-align: center;
        line-height: 50px;
        min-width: 150px;
    }

    /deep/ .is-active {
        background: #3285ea !important;
    }
}

.drawer {
    left: 150px;
    z-index: 999999999 !important;
    .projectTitle {
        background: #282b33;
    }
    .el-drawer__body span {
        width: 200px;
        height: 60px;
        color: #fff;
        font-weight: 500;
        line-height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        display: inline-block;
    }

    /deep/ .el-menu-item {
        padding: 0 !important;
    }

    /deep/ .is-active {
        background: #3285ea !important;
    }
}

.projectMenu {
    @maxheight: 95vh;
    height: @maxheight;
    border: 0;
    overflow: auto;
}

// 头部区域
.Header {
    height: 50px !important;
    background: #ffffff;
    position: fixed;
    left: 150px;
    right: 0;
    z-index: 999;
    .Header-ones {
        height: 50px;
        margin-right: 0px; 
        display: inline-flex;
        flex-direction: row; 
        span {
            margin-right: 8px;
        }
        .first {
            display: flex;
            align-items: center;
            align-content: center;
            flex-direction: row;
            justify-content: flex-start; 
        } 
        .cent {
            display: flex;
            align-items: center;
            align-content: center;
            flex-direction: row;
            justify-content: flex-start; 
            span {
                margin-right: 8px;
                background-color: cadetblue;
                color: white;
                padding:0 5px;
            } 
        } 
        .second {
            position:absolute;
            top:5px;
            right:20px;
            margin-left: auto;
            justify-content: flex-end;
            align-items: center;
            display: inline-flex;
            .btn {
                color: #3285ea; 
                cursor: pointer;
            }
        }
    }
}
.el-main {
    margin-top: 50px;
    margin-left: 150px;
}
// 内容区域
.mainCard {
    min-width: 1200px;
    min-height: 888px;
}
// @media screen and (min-device-width:1500px) and (max-device-width: 2200px) {
//   .mainCard {
//     background-color: pink;
//   }
// }
</style>