import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentProject: {}, //当前项目信息
        userName: "", //当前用户名
        Member: {}, //所有用户名列表
        projectName: {}, //所有项目列表
    },
    mutations: {
        saveCurrentProject(state, date) {
            state.currentProject = date
        },
        saveUserName(state, date) {
            state.userName = date
        },
        saveMember(state, date) {
            state.Member = date
        },
        saveProject(state, date) {
            state.projectName = date
        },
    },
    actions: {
        // 保存当前项目信息
        saveCurrentProject({ commit }, date) {
            commit("saveCurrentProject", date)
        },
        //保存当前用户名
        saveUserName({ commit }, date) {
            commit("saveUserName", date)
        },
        //保存当前用户名
        saveMember({ commit }, date) {
            commit("saveMember", date)
        },
        //保存当前用户名
        saveProject({ commit }, date) {
            commit("saveProject", date)
        },
    },
    modules: {},
    plugins: [createPersistedState()]
})